// // Globals
// $body-font-family: 'Work Sans', serif;
// $border-radius-root: 6px;
// $font-size-root: 14px;

// // Variables must come before the import
// $btn-letter-spacing: 0;
// $btn-font-weight: 400;
// $list-item-title-font-size: 0.929rem;
// $list-item-dense-title-font-size: 0.929rem;
// $list-item-dense-title-font-weight: initial;
// $fab-icon-sizes: (
//   'small': 20
// );
// $btn-sizes: (
//   'default': 41,
//   'large': 54
// );

/*
// default breakpoints
$grid-breakpoints: (
    'xs': 0,
    'sm': 600px,
    'md': 960px,
    'lg': 1280px - 16px,
    'xl': 1920px - 16px
  );
*/

$headings: (
  'h1': (
    'size': 36px,
    'line-height': 36px,
    'weight': 700
  ),
  'h2': (
    'size': 24px,
    'line-height': 30px,
    'weight': 700
  ),
  'h3': (
    'size': 18px,
    'line-height': 30px,
    'weight': 700
  ),
  'h4': (
    'size': 16px,
    'line-height': 24px,
    'weight': 700
  ),
  'h5': (
    'size': 14px,
    'line-height': 18px,
    'weight': 700,
    'letter-spacing': 0,
    'text-transform': none
  ),
  'h6': (
    'size': 14px,
    'line-height': 18px,
    'weight': 400,
    'letter-spacing': 0,
    'text-transform': none
  ),
  'button': (
    'size': 14px,
    'line-height': 18px,
    'weight': 400,
    'letter-spacing': 0,
    'text-transform': none
  ),
  'subtitle-1': (
    'size': 14px,
    'line-height': 18px,
    'weight': 700,
    'letter-spacing': 0,
    'text-transform': none
  ),
  'subtitle-2': (
    'size': 14px,
    'line-height': 18px,
    'weight': 400,
    'letter-spacing': 0,
    'text-transform': none
  ),
  'body-1': (
    'size': 16px,
    'line-height': 18px,
    'weight': 400,
    'letter-spacing': 0,
    'text-transform': none
  )
);

$stepper-elevation: 0;
$stepper-header-divider-margin: 0 -16px;
$stepper-header-elevation: 2;

$pagination-navigation-margin: 3px 0;
$pagination-navigation-height: 18px;
$pagination-navigation-width: 18px;
$pagination-item-font-size: 16px;
